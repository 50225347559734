<template>
  <!-- Tasks -->
  <div class="">
    <div class="d-flex justify-content-between">
      <div class="h5">Tasks</div>
      <div>
        <button class="btn btn-success" @click="createTaskModal.show()">
          Start new task
        </button>
      </div>
    </div>

    <!-- Tasks table -->
    <div style="margin-top: -5px">
      <div v-if="fetchingTasks">
        <img class="loader-lg" src="@/assets/loader.gif" />
      </div>
      <div v-else-if="errorFetchingTasks">
        Error loading tasks: {{ errorFetchingTasks }}
      </div>
      <TasksTable v-else :data="tasks"></TasksTable>
    </div>

    <!-- Task create modal -->
    <div
      class="modal fade"
      ref="createTaskModal"
      tabindex="-1"
      id="createTaskModal"
      aria-labelledby="createTaskModalLabel"
      aria-hidden="true"
      data-backdrop="false"
    >
      <div
        class="
          modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create a new Task</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <label for="inputEmail4">Athlete:</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="selectedAthlete.id"
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <label for="inputEmail4">Viewpoint:</label>
                <select class="form-control" v-model="taskData.viewpointId">
                  <option value="">Please select one</option>
                  <option
                    v-for="viewpoint in Object.values(selectedAthleteViewpoints)"
                    :key="viewpoint.id"
                    :value="viewpoint.id"
                  >
                    {{ viewpoint.name || viewpoint.id }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <label for="inputEmail4">Task Type:</label>
                <select class="form-control" v-model="taskData.taskType">
                  <option value="">Please select one</option>
                  <option
                    v-for="taskType in Object.values(taskTypes)"
                    :key="taskType.id"
                    :value="taskType.id"
                    :disabled="taskType.disabled"
                  >
                    {{ taskType.title }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-2" v-for="argument in currentArguments" :key="argument.id">
              <div class="col">{{argument.text }}</div>
              <div class="col">
                <input class="form-control" :type="argument.type" v-model="taskData.arguments[argument.id]">
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="submitTask"
              :disabled="creatingTask"
            >
              Create Task<img
                v-if="creatingTask"
                class="loader"
                src="@/assets/loader.gif"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TasksTable from "@/components/TasksTable";
import { getTasks, createTask } from "@/api";
import SelectAthlete from "@/components/SelectAthlete.vue";
import { mapGetters } from "vuex";

export default {
  mounted() {
    console.log(`Mounted, loading tasks`);
    this.fetchTasks();
    this.createTaskModal = new bootstrap.Modal(this.$refs.createTaskModal);
  },

  data() {
    return {
      createTaskModal: null,
      taskTypes: {
        person_identification: {
          id: "person_identification",
          title: "Player Team Identification",
          arguments: [],
        },
        demo_creation_v1: {
          "id": "demo_creation_v1",
          "title": "Demo Creation (v1)",
          arguments: [
            { id: "bluePlayerHeight", path: "bluePlayerHeight", text: "Blue Player Height (m)", type: "text" },
            { id: "redPlayerHeight", path: "redPlayerHeight", text: "Red Player Height (m)", type: "text" },
            { id: "sections", path: "sections", text: "Section: (E.g. 00:02:00-00:02:10 or 120-130)", type: "text" },
          ]
        },
        pose_extraction_v1: {
          "id": "pose_extraction_v1",
          "title": "Pose Extraction (v1)",
          arguments: []
        },
        collission_detection: {
          id: "collission_detection",
          title: "Collission Detection",
          disabled: true,
          arguments: []
        },
        event_detection: {
          id: "event_detection",
          title: "Event Detection",
          disabled: true,
          arguments: []
        },
      },
        tasks: {},
        fetchingTasks: false,
        errorFetchingTasks: false,
        creatingTask: false,
        taskData: {
          taskType: "",
          viewpointId: "",
          arguments: {},
        },
    };
  },
  components: {
    TasksTable,
    SelectAthlete,
  },
  computed: {
    ...mapGetters(["selectedAthlete", "selectedAthleteViewpoints"]),
    currentArguments() {
      return this.taskTypes[this.taskData.taskType] ? this.taskTypes[this.taskData.taskType].arguments : []
    }
  },
  methods: {
    async fetchTasks() {
      this.fetchingTasks = true;
      try { 
        let { resources } = (await getTasks({ athleteId: this.selectedAthlete.id })).data;
        console.log("Got tasks");
        for (let taskId of Object.keys(resources)) {
          this.tasks[taskId] = resources[taskId];
          this.$store.commit("UPDATE_TASK", this.tasks[taskId]);  
        }
      } catch(e) {
        this.errorFetchingTasks = e.message;
      } finally {
        this.fetchingTasks = false;
      }
    },
    async submitTask() {
      this.creatingTask = true;
      try {
        let response = await createTask({
          ...this.taskData,
          athletes: {
            [this.selectedAthlete.id]: true
          },
        });

        await this.fetchTasks();
        
        console.log("Tried to hide");
        // Redirect to the task?
        // bootstrap.Modal.getInstance(this.$refs.createTaskModal).hide()
        this.createTaskModal.hide()
        this.$router.push(`/Task/${response.data.id}`)
      } catch (e) {
        console.error(e.stack);
        this.Swal.fire({ title: "Uh-o", "text": e.message, icon: 'error' });
      } finally {
        this.creatingTask = false;
      }
    },

  },
  // watch: {
  //   selectedAthlete() {
  //     this.fetchTasks();
  //   }
  // },
};
</script>