<template>
    <div>
        <table class="table table-hover table-responsive">
            <thead>
                <tr>
                    <th scope="col">Task ID</th>
                    <th scope="col">Target ID</th>
                    <th scope="col">Processing Duration</th>
                    <th scope="col">Type</th>
                    <th scope="col">State</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="task in tasks" :key="task.id"  @click="gotoTask(task.id)" style="cursor:pointer;">
                    <th class="id" scope="row">{{task.id}}</th>
                    <td class="id">{{task.viewpointName || task.matchId || task.viewpointId || "-" }}</td>
                    <td>{{task.duration ? humanizeDuration(task.duration * 1000, { largest: 2 }) :  "-" }} </td>
                    <td>{{task.taskType || "-" }}</td>
                    <td>{{task.state || "-" }}</td>
                    <td>
                        <div @click.stop="deleteTask(task.id)" class="btn btn-danger btn-xs" size="sm" variant="danger">Delete</div>
                        <div v-if="task.state == 'COMPLETED' && task.metrics" @click.stop="downloadMetrics(task.id)" class="ms-1 btn btn-success btn-xs" size="sm" variant="success">Download</div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-if="tasks.length == 0" style="margin-top: -15px; margin-left: 0px; margin-bottom: 10px;">No tasks</div>
    </div>
</template>

<script>
const humanizeDuration = require("humanize-duration");

import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            deletedIds: []
        };
    },
    props: {
        'data': {
            type: Object,
        },
    },
    computed: {
        ...mapGetters(['selectedAthlete']),
        tasks() {
            return Object.values(this.data).filter(x => this.deletedIds.indexOf(x.id) == -1);;
        }
    },
    methods: {
        humanizeDuration,
        gotoTask(taskId) {
            this.$router.push({ 
                name: "Task",
                params: {
                    id: taskId
                }
            })
        },
        async deleteTask(taskId) {
            let { dismiss } = await this.Swal.fire({
                title: "Are you sure?",
                text: "This will permanently delete the resourec",
                showCancelButton: true,
                showConfirmButton: true,
            });
            if (dismiss) return;

            // Delete
            this.Swal.showLoading();
            await this.$store.dispatch("deleteTask", taskId);
            this.deletedIds.push(taskId)
            
            this.Swal.close()
        },
        downloadMetrics(taskId) {
            window.open(this.tasks.find(x => x.id == taskId).metrics, '_blank').focus();
        }
    }
}
</script>

<style scoped>
.table > :not(caption) > * > * { 
    padding: 0;
}

</style>